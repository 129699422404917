import React from "react"
import {Field, Form, Formik, FormikHelpers} from "formik"
import PropTypes from "prop-types"

interface Values {
	password: string
}

const StagingLogin = ({onSubmit}) => {
	return (
		<Formik
			initialValues={{
				password: ""
			}}
			onSubmit={(
				values: Values,
				{setSubmitting}: FormikHelpers<Values>
			) => {
				setSubmitting(false)
				onSubmit(values)
			}}
		>
			<Form>
				<Field className="form-control mb-2 data-cypress-dev-login-form" id="password" name="password" placeholder="Password" type="password" />
				<button className="btn btn-primary data-cypress-dev-login-form-button" type="submit">Login</button>
			</Form>
		</Formik>
	)
}

StagingLogin.propTypes = {
	onSubmit: PropTypes.func
}

export default StagingLogin
